import { ITransaction, Transaction } from "@/models/Transaction";
import { CommonService } from "@/services/commonService";

const BASE_URL = "api/mobile/transactions/";

class TransactionsService extends CommonService {
  transactionDataToInstance(transactionData: ITransaction): Transaction {
    return Object.assign(new Transaction(), transactionData);
  }

  async list(): Promise<Transaction[]> {
    const result = await this.restService.get(BASE_URL);
    return result.data.map((item: ITransaction) =>
      this.transactionDataToInstance(item)
    );
  }
}

export default new TransactionsService();
