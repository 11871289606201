var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-4"
  }, [_c('v-row', {
    staticClass: "my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('history.consommations')))])]), _c('v-row', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "value": [0, 1]
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', [_vm._v(_vm._s(_vm.$t('history.en-cours')))])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    attrs: {
      "headers": _vm.deliveringHeaders,
      "items": _vm.openedTransactions,
      "item-key": "id",
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_c('div', [_vm._v(_vm._s(_vm.$t('history.aucune-consommation')))])];
      },
      proxy: true
    }, {
      key: "item.box_label",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.localisation))])];
      }
    }, {
      key: "item.pos",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "mr-2"
        }, [_c('v-avatar', {
          staticClass: "primary",
          attrs: {
            "size": "20"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": "",
            "x-small": ""
          }
        }, [_vm._v(_vm._s(item.isElec() ? 'mdi-lightning-bolt' : 'mdi-water'))])], 1)], 1), _c('span', [_vm._v(_vm._s(item.plug_label))])];
      }
    }, {
      key: "item.begin",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatDateTime(item.begin)))])];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.end ? _c('div', [_vm._v(_vm._s(_vm.formatDateTime(item.end)))]) : _vm._e()];
      }
    }, {
      key: "item.current_qty",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.getConsumption()))])];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', [_vm._v(_vm._s(_vm.$t('history.historique')))])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    attrs: {
      "headers": _vm.historyHeaders,
      "items": _vm.closedTransactions,
      "hide-default-footer": "",
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_c('div', [_vm._v(_vm._s(_vm.$t('history.aucune-consommation')))])];
      },
      proxy: true
    }, {
      key: "item.box_label",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.localisation))])];
      }
    }, {
      key: "item.pos",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "mr-2"
        }, [_c('v-avatar', {
          staticClass: "primary",
          attrs: {
            "size": "20"
          }
        }, [_c('v-icon', {
          attrs: {
            "dark": "",
            "x-small": ""
          }
        }, [_vm._v(_vm._s(item.isElec() ? 'mdi-lightning-bolt' : 'mdi-water'))])], 1)], 1), _c('span', [_vm._v(_vm._s(item.plug_label))])];
      }
    }, {
      key: "item.begin",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatDateTime(item.begin)))])];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.end ? _c('div', [_vm._v(_vm._s(_vm.formatDateTime(item.end)))]) : _vm._e()];
      }
    }, {
      key: "item.current_qty",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', [_vm._v(_vm._s(item.getConsumption()))])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "fixed": "",
      "fab": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function click($event) {
        return _vm.refresh();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }