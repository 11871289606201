import { Transaction } from "@/models/Transaction";
import transactionsService from "@/services/transactionsService";

export interface TransactionsStoreState {
  transactions: Transaction[];
}

class TranactionsStore {
  state: TransactionsStoreState = {
    transactions: [],
  };

  async refresh(): Promise<void> {
    this.state.transactions = await transactionsService.list();
  }

}

export default new TranactionsStore();
