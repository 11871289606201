




























































































import { Component, Vue } from "vue-property-decorator";
import transactionsStore, {
  TransactionsStoreState,
} from "@/stores/transactionsStore";
import { DataTableHeader } from "vuetify";
import { Transaction, STATUS_DELIVERING } from "@/models/Transaction";
import { routeToMap } from "@/router/router";

@Component
export default class History extends Vue {
  historyHeaders: DataTableHeader[] = [
    {
      text: this.$t('history.borne') as string,
      align: "start",
      sortable: false,
      value: "box_label",
    },
    {
      text: this.$t('history.n') as string,
      align: "start",
      sortable: false,
      value: "pos",
    },
    {
      text: this.$t('history.debut') as string,
      align: "start",
      sortable: false,
      value: "begin",
    },
    {
      text: this.$t('history.fin') as string,
      align: "start",
      sortable: false,
      value: "end",
    },
    {
      text: this.$t('history.quantitte') as string,
      align: "start",
      sortable: false,
      value: "current_qty",
    },
  ];
  allowNoQty: boolean = true;

  transactionsStoreState: TransactionsStoreState = transactionsStore.state;

  goToMap(): void {
    routeToMap();
  }

  get transactions(): Transaction[] {
    return this.transactionsStoreState.transactions;
  }

  get deliveringHeaders(): DataTableHeader[] {
    return this.historyHeaders.filter((h) => h.value != "end");
  }

  get filteredNoQtyTransactions(): Transaction[] {
    return this.transactions.filter(
      (t) => t.current_qty > 0 || this.allowNoQty
    );
  }

  get openedTransactions(): Transaction[] {
    return this.transactions.filter(
      (t) => t.status == STATUS_DELIVERING
    );
  }

  get closedTransactions(): Transaction[] {
    return this.filteredNoQtyTransactions.filter(
      (t) => t.status != STATUS_DELIVERING
    );
  }

  async refresh(): Promise<void> {
    try {
      transactionsStore.refresh();
    } catch (e) {
      this.errorNotify(this.$t('history.impossible-de-charger-lhistorique'), e as Error);
    }
  }

  mounted(): void {
    this.refresh();
  }
}
